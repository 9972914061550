import httpService from "./httpServices";
// hardcoded value hotelid
const hotelIds = [
  4508, 4509, 4510, 4606, 4607, 4609, 4610, 4611, 4612, 4680, 4618, 4619, 4620,
  4622, 4680, 5152, 5153, 5154, 5156, 5157, 5158, 5159, 5160, 5164, 5166, 5168,
  5170, 5171, 5179, 5180, 5909, 6056, 6057, 6196, 7156, 7157, 14615, 5172, 5173,
  5176, 5177, 5169, 5163, 5165, 4613, 4615, 4616, 4617, 4623, 5151, 4623,
];

export const getConversationsApi = (
  hotelIdString = hotelIds.join(","),
  page = 1,
  pageSize = 10
) => {
  // const hotelIdString = { hotelIdString };
  const validPage = page ?? 1;

  return httpService.get(
    `hotel/authorised/v1/chatbot-widget/conversations?hotelIds=${hotelIdString}&page=${validPage}&pageSize=20`
  );
};

export const getConversationByIdApi = (conversationId) => {
  return httpService.get(
    `hotel/authorised/v1/chatbot-widget/conversation?conversationId=${conversationId}`,
    {}
  );
};

// export const getConversationsApi = (hotelId = "", page = 1, pageSize = 100) => {
//   const validPage = page ?? 1;
//   return httpService.get(
//     `hotel/authorised/v1/chatbot-widget/conversations?hotelIds=${hotelId}&page=${validPage}&pageSize=${pageSize}`
//   );
// };
export const getMessagesApi = (
  conversationId = "",
  page = 1,
  pageSize = 12
) => {
  return httpService.get(
    `hotel/authorised/v1/chatbot-widget/messages?conversationId=${conversationId}&page=${page}&pageSize=${pageSize}`
  );
};

export const getEnabledHotelsApi = () => {
  return httpService.get(`hotel/authorised/v1/chatbot-widget/enabled-hotels`);
};
export const messageToGuestApi = (obj) => {
  return httpService.post(
    `hotel/authorised/v1/chatbot-widget/message-guest`,
    obj
  );
};
export const verifyNumber = (data) => {
  return httpService.post("users/v1/isExistAndSendOtp", data);
};
export const verifyOTP = (data) => {
  return httpService.post("users/v1/verifyOtp", data);
};
export const sendOtpEmailApi = (obj) => {
  return httpService.post(`users/v1/isExistAndSendOtpEmail`, obj);
};

export const verifyOTPEmail = (obj) => {
  return httpService.post("users/v1/verifyOtpEmail", obj);
};

export const verifyUserAuthProviderApi = (obj) => {
  return httpService.post(`users/v1/verify-user-auth-provider`, obj);
};
export const fetchMyHotelsApi = () => {
  return httpService.get(`hotel/authorized/v1/hotel/mine`);
};

export const toggleChatModeApi = (agent, sessionId) => {
  const requestBody = {
    agent,
    sessionId,
  };
  return httpService.post(
    `hotel/authorised/v1/chatbot-widget/toggle-agent`,
    requestBody
  );
};

export const markReadApi = (obj) => {
  return httpService.post(`hotel/authorised/v1/chatbot-widget/mark-read`, obj);
};

export const getUnreadCountApi = (hotelIds) => {
  return httpService.get(
    `hotel/authorised/v1/chatbot-widget/unread-count?hotelIds=${hotelIds}`
  );
};

// export const getUnreadCountApi = (hotelIds) => {
//   const ids = hotelIds.join(",");
//   return httpService.get(
//     `hotel/authorised/v1/chatbot-widget/unread-count?hotelIds=${ids}`
//   );
// };

export const getUnreadConversationsApi = (
  hotelIdString = hotelIds.join(","),
  page = 1,
  pageSize = 20
) => {
  const validPage = page ?? 1;
  return httpService.get(
    `hotel/authorised/v1/chatbot-widget/conversations?hotelIds=${hotelIdString}&page=${validPage}&pageSize=20&filter=unread`
  );
};

export const postAnnotationApi = (obj) => {
  return httpService.post("hotel/authorised/v1/chatbot-widget/annotation", obj);
};
