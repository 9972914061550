import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { MdOutlineMail, MdOutlinePhone } from "react-icons/md";
import storageService from "../../services/storageServices";
import { areAllEmailsValid } from "../../utils/utils";
import {
  sendOtpEmailApi,
  verifyNumber,
  verifyOTP,
  verifyOTPEmail,
  verifyUserAuthProviderApi,
  getEnabledHotelsApi,
  fetchMyHotelsApi,
  getConversationsApi,
} from "../../services/apiServices";
import Layout from "../../layout/fullpage";
import { useHotelContext } from "../../App";
import { trackEvent } from "../../analytics";

const AuthLoginPage = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [phoneFormat, setPhoneFormat] = useState({});
  const [otp, setOtp] = useState("");
  const [display, setDisplay] = useState(false);
  const [method, setMethod] = useState("phone");
  const [email, setEmail] = useState("");
  const [emailResponse, setEmailResponse] = useState(null);
  const [locationDetails, setLocationDetails] = useState({});
  const [phoneResponse, setPhoneResponse] = useState();
  const [hotelIds, setHotelIds] = useState([]);
  const defaultDialCode =
    storageService.get("country_code")?.toLowerCase() || "in";
  const {
    hotels,
    setHotels,
    selectedHotel,
    setSelectedHotel,
    setSelectedChat,
    selectedChat,
    setConversations,
  } = useHotelContext();

  const phoneInputStyle = {
    width: "100%",
  };
  const location = useLocation();

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        setLocationDetails(data);
        storageService.set("country_code", data.country_code);
        storageService.set("country_calling_code", data.country_calling_code);
        storageService.set("location_details", data);
      } catch (error) {
        console.error("Error fetching location:", error);
      }
    };
    fetchLocation();
  }, []);

  const sendOtpEmail = async (e) => {
    trackEvent("clicked-send-otp-mail-login");
    e.preventDefault();
    const emailArray = email.split(",").map((email) => email.trim());
    if (!emailArray || !areAllEmailsValid(emailArray)) {
      toast.error("Please enter valid email address");
      return;
    }

    const obj = {
      email: emailArray.join(","),
      appVersion: "1.1",
      osVersion: "1.1",
      latitude: locationDetails?.latitude || "20.5556666",
      longitude: locationDetails?.longitude || "25.5555555",
      regMedium: "adminpanel",
    };

    try {
      const response = await sendOtpEmailApi(obj);
      setEmailResponse(response);
      if (response?.status === 200) {
        setDisplay(true);
        toast.success("OTP sent successfully");
      } else {
        toast.error("Please try again later");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    }
  };

  const verifyTheEmailOtp = async (e) => {
    e.preventDefault();
    trackEvent("verified-otp-mail-login");
    if (!otp) {
      return toast.error("Please enter OTP");
    }
    const obj = {
      sessionId: emailResponse?.data.sessionId,
      email: emailResponse?.data.email,
      role: "HOTEL_OWNER",
      otp: otp,
    };
    try {
      const response = await verifyOTPEmail(obj);
      if (response.status === 200) {
        toast.success("OTP verified successfully");
        const token = response.data.token;
        const userId = response.data.userId;
        console.log("userid", userId);

        storageService.set("token", token);
        storageService.set("userId", userId);
        await handleSuccessfulLogin();
        // Handle successful login
      } else {
        toast.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    }
  };

  const sendOtpPhone = async (e) => {
    trackEvent("send-otp-clicked-login-phone");
    e.preventDefault();
    if (!phone) {
      toast.error("Please enter a valid phone number");
      return;
    }

    const obj = {
      dialCode: "+" + phoneFormat.dialCode,
      mobile: phone.slice(phoneFormat.dialCode.length),
      appVersion: "1.1",
      osVersion: "1.1",
      latitude: locationDetails?.latitude || "20.5556666",
      longitude: locationDetails?.longitude || "25.5555555",
      regMedium: "adminpanel",
    };

    try {
      const response = await verifyNumber(obj);
      if (response?.status === 200) {
        setPhoneResponse({
          sessionId: response.data.sessionId,
          dialCode: obj.dialCode,
          mobile: obj.mobile,
        });
        setDisplay(true);
        toast.success("OTP sent successfully");
      } else {
        toast.error("Please try again later");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const verifyThePhoneOtp = async (e) => {
    trackEvent("verify-otp-login-phone");

    e.preventDefault();
    if (!otp) {
      toast.error("Please enter OTP");
      return;
    }
    if (!phoneResponse) {
      // console.log("!phnresp");
      toast.error("Please request OTP first");
      return;
    }
    const obj = {
      sessionId: phoneResponse.sessionId,
      dialCode: phoneResponse.dialCode,
      mobile: phoneResponse.mobile,
      role: "HOTEL_OWNER",
      otp: otp,
    };
    try {
      const response = await verifyOTP(obj);
      if (response.status === 200) {
        toast.success("OTP verified successfully");
        const token = response.data.token;
        const userId = response.data.userId;
        storageService.set("token", token);
        storageService.set("userId", userId);
        await handleSuccessfulLogin();
      } else {
        toast.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    }
  };
  const handleChangeEmail = () => {
    trackEvent("clicked-edit-email");
    setEmail("");
    setOtp("");
    setDisplay(false);
  };
  const handleChangeNumber = () => {
    trackEvent("clicked-edit-number");
    setPhone(storageService.get("country_calling_code"));
    setOtp("");
    setDisplay(false);
  };

  const handleSuccessfulLogin = async () => {
    const hotelIdString = await fetchHotelIds();
    if (!hotelIdString) {
      toast.error("no conversations found");
    }

    try {
      const response = await getEnabledHotelsApi();
      console.log("res", response);

      if (response.status === 200) {
        const enabledHotels = response.data.enabledHotels;

        if (Array.isArray(enabledHotels) && enabledHotels.length > 0) {
          setConversations();
        } else {
          toast.error("No Active Bots found. Please contact support.");
          return;
        }

        const queryParams = new URLSearchParams(window.location.search);
        const conversationId = queryParams.get("conversationId");
        console.log("con", conversationId);

        if (conversationId) {
          trackEvent("searching-chat-by-conversationid", conversationId);
          navigate(
            `/apps/chats?redirect=${encodeURIComponent(
              location.pathname + location.search
            )}`
          );
        } else {
          navigate("/apps/chats");
          window.location.reload();
        }
        const conversations = await getConversationsApi(hotelIdString);
        console.log("Fetched Conversations:", conversations);
      }
    } catch (error) {
      toast.error("Failed to fetch enabled hotels. Please try again.");
    }
  };

  const fetchHotelIds = async () => {
    try {
      const response = await fetchMyHotelsApi();
      const ids = response.data.map((hotel) => hotel.id);
      setHotelIds(ids);
      const hotelIdString = ids.join(",");
      storageService.set("hotel_ids", hotelIdString);

      // console.log("Hotel ID String:", hotelIdString);
      return hotelIdString;
    } catch (error) {
      console.error("Error fetching hotel IDs:", error);
      return null;
    }
  };

  const handleGoogleLogin = async (response) => {
    try {
      const result = await verifyUserAuthProviderApi({
        token: response.credential,
        authProvider: "google",
        role: "HOTEL_OWNER",
        appVersion: "NA",
        osVersion: "NA",
        latitude: locationDetails?.latitude || "20.5556666",
        longitude: locationDetails?.longitude || "25.5555555",
        regMedium: "website",
      });
      if (result?.status === 200) {
        toast.success(result?.message);
        await handleSuccessfulLogin();
      } else {
        toast.error(result?.message);
      }
    } catch (err) {
      console.error(err);
      toast.error("Try again!");
    }
  };

  useEffect(() => {
    const google = window.google;
    if (google?.accounts?.id) {
      google.accounts.id.initialize({
        client_id:
          "1015179130342-8d1motv1eqonoo0sht9g51u1tr13cjqa.apps.googleusercontent.com",
        callback: handleGoogleLogin,
      });

      google.accounts.id.renderButton(
        document.getElementById("google-login-button"),
        { theme: "outline", size: "large" }
      );
    }
  }, []);

  return (
    <Layout title="Login" centered>
      <div className="container p-2 p-sm-4">
        <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
          <Row className="g-0 flex-lg-row-reverse">
            <Col lg="6">
              <Card.Body className="h-100 d-flex flex-column justify-content-center shadow">
                <div className="nk-block-head text-center">
                  <div className="nk-block-head-content">
                    <h3
                      className="nk-block-title mb-1"
                      style={{
                        color: "var(--Blue, #1870EB)",
                        fontFamily: "Inter Tight",
                        fontSize: "32px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                      }}
                    >
                      Login
                    </h3>
                  </div>
                </div>
                <div
                  className="d-flex mb-3"
                  style={{
                    borderBottom: "1px solid #D2D2D2",
                  }}
                >
                  <Button
                    variant={
                      method === "phone" ? "primary" : "outline-secondary"
                    }
                    style={{
                      border: "none",
                      color: method === "phone" ? "#1870EB" : "inherit",
                      backgroundColor:
                        method === "phone" ? "#EAF7FF" : "transparent",
                      fontWeight: "500",
                    }}
                    onClick={() => {
                      setMethod("phone");
                      setDisplay(false);
                      setOtp("");
                      trackEvent("login-type-phone");
                    }}
                    className="w-50 me-2"
                  >
                    {/* <MdOutlinePhone size={18} className="me-2" /> */}
                    Phone Number
                  </Button>
                  <Button
                    variant={
                      method === "email" ? "primary" : "outline-secondary"
                    }
                    style={{
                      border: "none",
                      color: method === "email" ? "#1870EB" : "inherit",
                      backgroundColor:
                        method === "email" ? "#EAF7FF" : "transparent",
                      fontWeight: "500",
                    }}
                    onClick={() => {
                      setMethod("email");
                      setDisplay(false);
                      setOtp("");
                      trackEvent("login-type-email");
                    }}
                    className="w-50"
                  >
                    {/* <MdOutlineMail size={18} className="me-2" /> */}
                    E-mail
                  </Button>
                </div>
                <Form>
                  {method === "phone" && (
                    <div>
                      <p
                        style={{
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "24px" /* 150% */,
                          letterSpacing: "-0.25px",
                          alignSelf: "stretch",
                        }}
                      >
                        Enter phone number
                      </p>
                      <PhoneInput
                        inputClass="form-control"
                        country={defaultDialCode}
                        value={phone}
                        onChange={(phone, format) => {
                          setPhone(phone);
                          setPhoneFormat(format);
                        }}
                        inputStyle={phoneInputStyle}
                        disabled={display}
                      />
                    </div>
                  )}
                  {method === "email" && (
                    <div>
                      <p
                        style={{
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "24px" /* 150% */,
                          letterSpacing: "-0.25px",
                          alignSelf: "stretch",
                        }}
                      >
                        Enter Email address
                      </p>
                      <Form.Control
                        type="email"
                        placeholder="enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        readOnly={display}
                      />
                    </div>
                  )}
                  {!display && (
                    <Button
                      variant="primary"
                      className="w-100 mt-3"
                      onClick={method === "email" ? sendOtpEmail : sendOtpPhone} // Replace with phone OTP function
                      style={{
                        background:
                          "linear-gradient(170deg, #516BD3 33.94%, #473FB8 115.39%)",
                      }}
                    >
                      Get OTP
                    </Button>
                  )}
                  {display && (
                    <>
                      <Form.Control
                        type="text"
                        placeholder="enter otp"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        className="mt-3"
                      />
                      <Button
                        variant="primary"
                        className="w-100 mt-3"
                        onClick={
                          method === "email"
                            ? verifyTheEmailOtp
                            : verifyThePhoneOtp
                        } // Replace with phone OTP verification
                        style={{
                          background:
                            "linear-gradient(170deg, #516BD3 33.94%, #473FB8 115.39%)",
                        }}
                      >
                        verify otp
                      </Button>
                      {method === "phone" && (
                        <p
                          style={{
                            color: "#00275e",
                            cursor: "pointer",
                            fontWeight: "bold",
                            textDecoration: "underline",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          onClick={() => handleChangeNumber()}
                        >
                          change number
                        </p>
                      )}
                      {method === "email" && (
                        <p
                          style={{
                            color: "#00275e",
                            cursor: "pointer",
                            fontWeight: "bold",
                            textDecoration: "underline",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          onClick={() => handleChangeEmail()}
                        >
                          change email
                        </p>
                      )}
                    </>
                  )}
                </Form>
                {/* <div className="my-4 text-center">
                  <OverlineTitle className="overline-title-sep">
                    <span>or</span>
                  </OverlineTitle>
                </div> */}
                <div
                  id="google-login-button"
                  className="d-flex justify-content-center mb-3"
                ></div>
                <p className="text-center small mt-4">
                  By signing in you agree to the {""}
                  <Link to="/terms-conditions">Terms and conditions</Link>
                </p>
              </Card.Body>
            </Col>
            <Col lg="6">
              <Card.Body style={{ height: "100%" }}>
                {/* <div className="mask mask-1"></div> */}
                <div
                  className="brand-logo h-100 d-none d-sm-flex"
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    // borderRight: "2px solid black",
                  }}
                >
                  <img
                    src="https://ik.imagekit.io/hotelzifyprod/logo.png"
                    alt="loginImg"
                    style={{ maxWidth: "50%", borderRadius: "50%" }}
                  />
                </div>
              </Card.Body>
            </Col>
          </Row>
        </Card>
      </div>
    </Layout>
  );
};

export default AuthLoginPage;
