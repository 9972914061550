import React, { useEffect, useLayoutEffect, useState } from "react";

import AppRoot from "../global/AppRoot";
import AppMain from "../global/AppMain";
import AppWrap from "../global/AppWrap";
import AppContent from "../global/AppContent";

import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import LayoutProvider from "./LayoutProvider";
import {
  fetchMyHotelsApi,
  getUnreadCountApi,
} from "../../services/apiServices";
import storageService from "../../services/storageServices";

function Default({ title, content, ...props }) {
  const [unreadCount, setUnreadCount] = useState(0);
  const [hotelIds, setHotelIds] = useState("");

  useLayoutEffect(() => {
    const updatedTitle = unreadCount > 0 ? `(${unreadCount}) ${title}` : title;
    document.title = updatedTitle;
  }, [unreadCount, title]);

  useEffect(() => {
    const fetchHotelIds = async () => {
      const storedHotelIds = storageService.get("hotel_ids");
      if (storedHotelIds) {
        setHotelIds(storedHotelIds);
        return storedHotelIds;
      } else {
        try {
          const response = await fetchMyHotelsApi();
          const ids = response.data.map((hotel) => hotel.id);
          const hotelIdString = ids.join(",");
          setHotelIds(hotelIdString);
          storageService.set("hotel_ids", hotelIdString);
          return hotelIdString;
        } catch (error) {
          console.error("Error fetching hotel IDs:", error);
          return null;
        }
      }
    };
    const fetchUnreadCount = async () => {
      const ids = await fetchHotelIds();
      if (ids) {
        try {
          const response = await getUnreadCountApi(ids);
          setUnreadCount(response.data.count);
        } catch (error) {
          console.error("Error fetching unread count:", error);
        }
      }
    };
    fetchUnreadCount();
  }, []);

  return (
    <LayoutProvider>
      <AppRoot>
        <AppMain>
          <Sidebar />
          <AppWrap>
            <Header />
            <AppContent content={content}>{props.children}</AppContent>
            {/* <Footer /> */}
          </AppWrap>
        </AppMain>
      </AppRoot>
    </LayoutProvider>
  );
}

export default Default;
