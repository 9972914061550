import React from "react";
import classNames from "classnames";

import SimpleBar from "simplebar-react";
import { Logo } from "../../../components/";
import Menu from "./Menu";
import ToggleCompact from "../Toggle/Compact";
import ToggleSidebar from "../Toggle/Sidebar";

import { useLayout, useLayoutUpdate } from "./../LayoutProvider";
import { Media } from "reactstrap";

function Sidebar() {
  const layout = useLayout();
  const layoutUpdate = useLayoutUpdate();

  const compClass = classNames({
    "nk-sidebar nk-sidebar-fixed": true,
    "is-compact": layout.sidebarCompact,
    "sidebar-active": layout.sidebarActive,
    [`is-${layout.sidebarVariant}`]: layout.sidebarVariant,
  });

  return (
    <>
      {layout.sidebarActive && (
        <div
          className="sidebar-overlay"
          onClick={layoutUpdate.sidebarMobile}
        ></div>
      )}
      <div className={compClass}>
        <div className="nk-sidebar-element nk-sidebar-head">
          <div className="nk-sidebar-brand">
            {/* <Logo /> */}
            <Media shape="circle" middle>
              {/* <Image src={selectedChat.avatar} alt={selectedChat.name} /> */}
              <img
                src="https://cdn.prod.website-files.com/665dbdf119ee43cd42be2657/665dfa4ae79bf116e4bb5ec0_Group%202745%402x-p-500.png"
                alt="loginImg"
                style={{ maxWidth: "100%", minWidth: "150px" }}
              />
            </Media>
            <ToggleCompact />
            <ToggleSidebar />
          </div>
        </div>
        <div className="nk-sidebar-element nk-sidebar-body">
          <div className="nk-sidebar-content">
            <SimpleBar className="nk-sidebar-menu">
              <Menu />
            </SimpleBar>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
