import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../layout/fullpage";
import { Image } from "../../components";

function Wip() {
  return (
    <Layout title="Work In Progress" mask="2" centered>
      <div className="container">
        <div className="nk-block">
          <div className="nk-block-content wide-sm text-center mx-auto">
            <Image
              src="/images/error/c.png"
              alt="work in progress image"
              className="mb-4"
            />
            <h2 className="nk-error-title mb-2">
              This page is under progress!
            </h2>
            <p className="nk-error-text">
              We are working hard to bring this page to life. Please check back
              later for updates or explore other sections of our website.
            </p>
            <Link to="/" className="btn btn-primary mt-1">
              <em className="icon ni ni-arrow-left"></em>
              <span>Back To Home</span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Wip;
