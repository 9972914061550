import React from "react";
import { Card, CardBody } from "reactstrap";
import "./Terms.css";

const Terms = () => {
  const pp = "https://hotelzify.com/privacy-policy";

  return (
    <div className="termsconditions">
      <div className="header">
        <h1>TERMS OF SERVICE AGREEMENT</h1>
      </div>
      <div className="text">
        THIS TERMS OF SERVICE AGREEMENT (“Agreement”) is made by and between:
        Hotelzify Private Limited, a company incorporated under the provisions
        of the Companies Act, 2013, bearing CIN U72900KA2022PTC167171, having
        its registered office at Greenwood Residency, S 4 101 Doddakannahalli
        Village Bangalore Bangalore KA 560035 (hereinafter referred to as the
        &quot;Company&quot; or “Hotelzify”, which expression shall include its
        affiliates, successors and permitted assigns) of the FIRST PART; And
      </div>
      <div className="text">
        Any person, individual or artificial legal entity being a hotel, hostel,
        or accommodation operator or a property owner or management of a hotel,
        hostel, accommodation or property who is Registered (defined
        hereinafter) on the Company’s Application (defined hereinafter)
        (hereinafter referred to as the &quot;Property&quot; or “You” or “Your”
        or “User”, which expression shall include its affiliates, successors and
        permitted assigns) of the SECOND PART. The Company and the Property are
        individually referred to as “Party” and collectively referred to as the
        “Parties.”
      </div>
      <div className="text">
        BY CLICKING THE ACCEPTANCE BUTTON OR ACCESSING, USING OR INSTALLING ANY
        PART OF THE SERVICE, PROPERTY EXPRESSLY AGREES TO AND CONSENTS TO BE
        BOUND BY ALL OF THE TERMS OF THIS AGREEMENT. IF PROPERTY DOES NOT AGREE
        TO ALL OF THE TERMS OF THIS AGREEMENT, THE BUTTON INDICATING
        NON-ACCEPTANCE MUST BE SELECTED, COMPANY WILL PROMPTLY CANCEL THIS
        TRANSACTION AND PROPERTY MAY NOT ACCESS, USE OR INSTALL ANY PART OF THE
        SERVICE.
      </div>
      <div className="text">
        <b>WHEREAS:</b>
      </div>
      <div className="text pl-3">
        A. The Company is engaged in the business of providing services related
        to and enabling Properties through the Company’s Application (defined
        hereinafter), to create and maintain a website by provision of
        information technology support for their property through which
        potential guests may book rooms on the property.
      </div>
      <div className="text pl-3">
        B. The Property is in the business of providing accommodation to
        potential guests and has represented to the Company that it has the
        necessary legal and business setup to provide accommodation.
      </div>
      <div className="text pl-3">
        C. The Property is desirous of availing the Services (defined
        hereinafter) of the Company.
      </div>
      <div className="text pl-3">
        D. The Company through its Application (defined hereinafter) has agreed
        to provide the Property its Services in keeping with the terms and
        conditions recorded hereinbelow.
      </div>
      <div className="text">
        NOW THEREFORE, the Parties hereby agree as follows:
      </div>
      <div className="text">
        <b>1. Definitions</b>
      </div>
      <div className="text">
        <b>“Applicable Laws(s)”</b>means all statutes, enactments, acts of
        legislature or parliament, ordinances, rules, bye-laws, regulations,
        notifications, policies, directions, directives and orders as such are
        in effect as of the date hereof or as may be amended, modified, enacted
        or revoked from time to time hereafter or other governmental
        restrictions or any similar form of decision of, or determination by any
        government, statutory authority, tribunal, board, court having
        jurisdiction over the matter in question in India.
      </div>
      <div className="text">
        <b>“Application”</b>refers to the mobile application by means of which
        the Company makes its Services available to Properties.
      </div>
      <div className="text">
        <b>“Fee”</b>
        shall mean the fee charged by the Company in lieu of providing their
        Services, which shall be 2.5% of the booking amount if the booking is
        received independently by the Property, or shall be within a range of
        7.5% - 20% of the booking amount if the booking is received on account
        of the Company being a Google Hotel Partner. The fee may be revised by
        the Company with due communication to the Property.
      </div>
      <div className="text">
        <b>“Customer”</b>shall mean a potential guest of the Property, who
        wishes to book an accommodation/ room.
      </div>
      <div className="text">
        <b>“Information”</b>shall include Property’s information and any other
        data/information that may be shared voluntarily by the Property or that
        may be involuntarily gathered involuntarily by the Application and
        includes information such as details of the property, accommodation,
        rooms, amenities, location, pricing, pictures and so on of the property
        for which the Property is desirous of generating a User Website;
      </div>
      <div className="text">
        <b>“Marks”</b>refers to the mark “Hotelzify” and other associated logos,
        designs and brands, if any, that are proprietary of the Company.
      </div>
      <div className="text">
        <b>“Payment Gateway”</b>means the electronic payment service provided on
        the Application for authorising debit card, credit card, net banking,
        online wallet or any other method of payments included by the Company
        for processing all payments to be made by Properties.
      </div>
      <div className="text">
        <b>“Privacy Policy”</b>refers to the privacy policy published on the
        Company’s website, which may be accessed at
        <a href="https://hotelzify.com/privacy-policy">
          https://hotelzify.com/privacy-policy
        </a>{" "}
        which forms an integral part of this Agreement;
      </div>
      <div className="text">
        <b>“Register”</b> or <b>“Registered”</b> or <b>“Registration”</b> refers
        to the formal procedure that the Property undertakes as per Clause [●]
        to complete the process of login with the Application to avail the
        Services of the Company.{" "}
      </div>
      <div className="text">
        <b>“Services”</b>shall include (i) the services provided by the Company
        through its Application for website generation on the basis of
        Information provided by the Property in order to enable the Property to
        receive bookings from potential guests; (ii) related technical support
        for such website; and (iii) any other related services such as IT
        support, selling domain name, which the Parties may agree upon.
      </div>
      <div className="text">
        <b>“User Website”</b>shall mean the website generated for the Property
        by the Application based on the Information shared by the Property.
      </div>
      <div className="text">
        <b>2. GENERAL</b>
      </div>
      <div className="text pl-3">
        2.1. This document is an electronic record in terms of the Information
        Technology Act, 2000 (“Act”) and the Applicable Laws. This electronic
        record is generated by a computer system and does not require any
        physical or digital signatures.
      </div>
      <div className="text pl-3">
        2.2. This document is published in accordance with the provisions of
        Rule 3 (1) of the Information Technology (Intermediary Guidelines and
        Digital Media Ethics Code) Rules, 2021 (“Rules”) that require publishing
        the rules and regulations, privacy policy and terms of use for access or
        usage of the Application.
      </div>
      <div className="text pl-3">
        2.3. Your use of the Application is subject to this Agreement together
        with the <a href={pp}>Privacy Policy</a>
        ,including the applicable policies which are incorporated herein by way
        of reference. If You click the Accept button and continue to use the
        Application, You agree to be subject to the applicable rules,
        guidelines, policies, terms and conditions and the same shall be deemed
        to be incorporated into this Agreement and be considered as part and
        parcel of this Agreement.
      </div>
      <div className="text pl-3">
        CLICKING THE ACCEPT BUTTON, ACCESSING, BROWSING OR OTHERWISE USING THE
        APPLICATION INDICATES YOUR ACCEPTANCE OF ALL THE TERMS AND CONDITIONS IN
        THIS AGREEMENT AND OUR <a href={pp}>PRIVACY POLICY</a>
        ,PLEASE READ THIS AGREEMENT CAREFULLY BEFORE PROCEEDING. YOU DECLARE
        YOUR WILLINGNESS TO ABIDE AND BE BOUND BY THIS AGREEMENT THROUGH YOUR
        USE OF THE APPLICATION. IF YOU DO NOT AGREE WITH THIS AGREEMENT, PLEASE
        CLICK THE DECLINE BUTTON AND PLEASE DO NOT USE THE APPLICATION. This
        Agreement will be effective immediately upon Your acceptance of this
        Agreement, Your clicking the Accept button and use of the Application
        being indicative of such acceptance. This Agreement shall be
        enforceable, in the same manner as any other written agreement.
      </div>
      <div className="text pl-3">
        2.5. You agree that the Company may revise, modify, add, or remove
        portions of this Agreement from time to time by updating and uploading
        the same on the Application and You agree to abide by and be bound by
        such Agreement. Your continued use of the Application affirms Your
        consent to accept any new or revised Agreement. We encourage You to
        check the Application periodically to ensure that You are aware of the
        latest Agreement.
      </div>
      <div className="text">
        <b>3. Scope of Services</b>
      </div>
      <div className="text pl-3">
        3.1. The Company through its Application generates a User Website, which
        is Customer facing, based on the Information provided by the Property.
        The Application also generates a link which the Property may share with
        its Customers.
      </div>
      <div className="text pl-3">
        3.2. The User Website and the link facilitate the connection of a
        Property with Customers, who may via the User Website book
        accommodations/rooms displayed on the User Website.
      </div>
      <div className="text pl-3">
        3.3. All details concerning accommodations and the property are
        displayed on the User Website, as provided by the Property. It is the
        sole responsibility of the Property to ensure that the Information is
        accurate. The User Website is merely maintained by the Company, by
        providing information technology support, on behalf of the Property. The
        Property agrees and acknowledges that the Company is merely providing
        information technology services to the Property. The Property further
        agrees and acknowledges that it is the sole owner of and solely
        responsible for the User Website. Notwithstanding anything contained
        herein, the Company is under no responsibility for any and all costs,
        losses, damages, lawsuits, deficiencies, claims and expenses (including
        court costs and reasonable attorney’s fees) (“Losses”) in connection
        with or arising out of or resulting from or incidental to the User
        Website.
      </div>
      <div className="text pl-3">
        3.4. The Company is a Google Hotel Partner, which means that the Company
        sends the property prices and inventory shared by the Property to Google
        so that the Property’s property will rank higher with an official site
        tag.
      </div>
      <div className="text pl-3">
        3.5. The Property may opt to give permission to the Company by clicking
        on the tab for the same to distribute details of the Property across
        other platforms in order increase visibility for the Property.
      </div>
      <div className="text pl-3">
        3.6. Customers may book an accommodation via the User Website, for which
        they may make either full or part payment online through a Payment
        Gateway, or they may opt to pay directly at the property at the time of
        check-in, as per the specifications enabled by the Property concerning
        payment options. In case the Customer chooses to pay online, the amount
        paid by the guest shall be credited to an account maintained by the
        Company for this purpose.
      </div>
      <div className="text pl-3">
        3.7. The Property’s bookings will reflect in the Application. The
        Property may either accept the booking or reject if it is fully occupied
        or if there has been a revision in its pricing, which is not acceptable
        to the Customer.
      </div>
      <div className="text pl-3">
        3.8. If the booking is accepted by the Property, the Customer is
        informed of the same and the time of check-in the Company shall deduct
        its Fee for provision of its Services and transfer the remaining amount
        for the booking to the Property.
      </div>
      <div className="text pl-3">
        3.9. If the booking is rejected by the Property, the booking amount
        shall be refunded to the Customer, as per the refund policy of the
        respective Property. The Company is merely a facilitator and is not
        responsible for the administrative decisions or policies of the
        Property.
      </div>
      <div className="text pl-3">
        3.10. If the booking is cancelled by the Customer, then the booking
        amount shall be refunded to the Customer, as per the refund policy of
        the respective Property.
      </div>
      <div className="text pl-3">
        3.11. Notwithstanding anything contained herein, the Property undertakes
        to ensure that the Company shall receive a percentage of its Fee, to be
        mutually determined by the Parties, even in cases of rejected or
        cancelled bookings in terms of Clauses 3.8 and 3.9 hereinabove. The
        Company shall deduct such mutually determined percentage of its Fee for
        the Services and transfer the remaining amount for the booking to the
        Customer, as per the Property’s refund policy. In case of any deficit,
        the Property agrees to pay such amount to the Customer and the Company
        will not be responsible for the same.
      </div>
      <div className="text pl-3">
        3.12. The Company does not charge any fees for generation of a standard
        User Website. It is hereby clarified that in case the Property wishes
        for any customisation, they may contact the Company, and the Company may
        at its discretion, for a fee to be mutually determined, provide such
        customisations in the User Website.
      </div>
      <div className="text">
        <b>4. Registration</b>
      </div>
      <div className="text pl-3">
        4.1. The Property cannot access the Application without Registration. To
        use the features, the Property will need to register and create a
        profile with accurate and complete Information. The Property’s profile
        must be their own or of a property that the Property is entitled and
        duly authorised to represent, and the Property shall not use or provide
        any Information that is offensive or that violates any third party’s
        rights.
      </div>
      <div className="text pl-3">
        4.2. The Services are provided on the Application, in the manner
        described hereinbelow:
      </div>
      <div className="text pl-5">
        4.2.1. The Property creates an account as a representative of property
        that is operative under the Applicable Laws (“Account”), by way of phone
        number and OTP.
      </div>
      <div className="text pl-5">
        4.2.2. The Property shall enter detailed Information regarding the
        property as requested for by the Application.
      </div>
      <div className="text pl-5">
        4.2.3. The Property shall approve the Information and the Application
        shall based on the Information provided by the Property instantly
        generate an User Website and share a link of the same with the Property,
        which the Property can circulate in order to gain bookings from
        Customers.
      </div>
      <div className="text pl-5">
        4.2.4. Property will be solely responsible for all activities that occur
        under the Property’s name and the Account thereunder. The Company will
        not be liable to any person and / or entity and / or governmental
        authority for any loss or damage which may arise as a result of any
        failure by the Property to protect their login credentials and/or the
        OTP.
      </div>
      <div className="text pl-3">
        4.3. The Company reserves the right to refuse Service and/or terminate
        Account.
      </div>
      <div className="text">
        <b>5. Registration Criteria</b>
      </div>
      <div className="text pl-3">
        5.1. In case of a natural person, such person must be above the age of
        18 (Eighteen), of sound mind and must be eligible to enter into a
        contract as per the Indian Contract Act, 1872. In case of a legal
        entity, such entity must be validly registered and existing under the
        laws of India and must have the authority to enter into this Agreement
        with the Company.
      </div>
      <div className="text pl-3">
        5.2. The Application and the Services therein are intended only for
        Registered Properties.
      </div>
      <div className="text pl-3">
        5.3. The use of the Application is offered to the Property and is
        conditional on their acceptance and consent of all the terms, conditions
        and notices contained in this Agreement. Upon acceptance, this Agreement
        will be effective and binding upon the Property along with any
        amendments made by the Company at its sole discretion and posted on the
        Application and the Property will not claim invalidity of this Agreement
        merely on the grounds that these terms are being concluded
        electronically.
      </div>
      <div className="text pl-3">
        5.4. If the Property chooses to get Registered, they agree and warrant
        that they, as per Applicable Laws, are validly registered, licensed,
        authorised, and qualified to provide accommodations/rooms. It is hereby
        clarified that the Company is under no obligation to verify the
        Property’s credentials or Information and shall not be responsible for
        any Losses on account of any misrepresentations on the Property’s part.
      </div>
      <div className="text">
        <b>6. Responsibilities of the Property</b>
      </div>
      <div className="text pl-3">
        6.1. The Property takes full and complete responsibility for the User
        Website. The responsibilities of a Property, as applicable, are the
        following:
      </div>
      <div className="text pl-5">
        6.1.1. The Property shall be solely responsible for bookings,
        cancellations, refunds.
      </div>
      <div className="text pl-5">
        6.1.2. The Property undertakes that all Information (including a current
        email address) provided to the Application are up to date and shall be
        kept up to date by then. The Property is responsible for providing true,
        accurate and complete Information that is a true representation of the
        details of its property.
      </div>
      <div className="text pl-3">
        6.2. The Property takes full and complete responsibility that it is
        operating in compliance with all the applicable laws, regulations and
        statues, and it has the requisite licenses in place to operate its
        business.
      </div>
      <div className="text pl-3">
        6.3. The Property takes full and complete responsibility that it has
        full right, title and interest in and to all trade names, trademarks,
        service marks, logos, symbols, proprietary marks and other intellectual
        property marks (&quot;IPR&quot;) which it provides to the Application,
        for use related to the Services, and that any IPR provided by a the
        Property will not infringe the marks of any third party.
      </div>
      <div className="text pl-3">
        6.4. The Property shall permit the usage of its logo, trademarks,
        symbols, service mark etc. to enable the Company to fulfil its rights
        and obligations under this Agreement.
      </div>
      <div className="text pl-3">
        6.5. The Property shall maintain rate parity, and room availability
        parity between the User Website and other travel agents, other sales
        channels of third parties and the Property itself.
      </div>
      <div className="text pl-3">
        6.6. The Property agrees and undertakes that it shall at all times act
        in good faith and shall not in any way commit acts prejudicial to the
        interest of the Company or acts which in sole discretion of the Company
        may amount to defrauding the Company.
      </div>
      <div className="text pl-3">
        6.7. The Property further represents and warrants:
      </div>
      <div className="text pl-5">
        6.7.1. That The Property has the right, authority, and capacity to enter
        into this Agreement on its own behalf and on behalf on any entity for
        whom the Property is acting and to abide by all of the terms and
        conditions contained herein, and that if any aspect of the Property’s
        participation violates provisions of the law to which the Property is
        subject, the Property will cease using the Services and close the
        Property’s Account;
      </div>
      <div className="text pl-5">
        6.7.2. That the Property is at eligible as per Clause 5 of this
        Agreement;
      </div>
      <div className="text pl-5">
        6.7.3. That the Property shall not use a false name or email address
        owned or controlled by another person with the intent to impersonate
        that person or for any other reason;
      </div>
      <div className="text pl-5">
        6.7.4. That the Property shall not use Login Credentials that are
        subject to any rights of a person other than the Property without
        appropriate authorization;
      </div>
      <div className="text pl-5">
        6.7.5. That the Property shall comply with the terms and conditions
        stipulated in this Agreement;
      </div>
      <div className="text pl-5">
        6.7.6. That all the representations made by the Property to the Company
        including in accordance with these terms and conditions are true,
        correct and complete and that the Property will update the Information
        with the Company as needed so that it remains true, correct and
        complete;
      </div>
      <div className="text pl-5">
        6.7.7. That the Property will conduct the Property in a professional
        manner in all the Property’s interactions with any Customers, the
        Company, their respective members, employees and officers and shall not
        defame, harass, intimidate, threaten, disparage, libel or otherwise make
        false statement concerning any of the aforesaid persons in the course of
        the Property’s interactions with them or third parties;
      </div>
      <div className="text pl-5">
        6.7.8. That The Property’s usage of the Application will not, in any
        manner, directly or indirectly, violate terms of any contractual
        arrangement binding on the Property. Further, the Property agree not to
        use the Application in a manner that results in an event where the
        Property may experience a conflict of interest;
      </div>
      <div className="text pl-5">
        6.7.9. That the Property shall cooperate in good faith with Customers,
        the Company and its personnel, and their respective employees and
        officers to give effect to and honour the terms of any contract entered
        into between The Property and such persons, whether or not executed
        through the Application.
      </div>
      <div className="text">
        <b>7. Risk Disclosure</b>
      </div>
      <div className="text pl-3">
        7.1. No advice. The Company does not provide or offer any advice
        whatsoever. No information or knowledge provided on the Application
        shall be construed and / or interpreted as such advice. No person and /
        or entity, under any circumstances, should make any decision(s) based
        solely on the information provided on the Application.
      </div>
      <div className="text pl-3">
        7.2. No representations. The Company makes no representation, warranty
        and / or guarantee as to the quality, accuracy, completeness,
        performance, or fitness of information, advice, tool, calculator,
        analysis, report, data, content news, price, statistic, comment,
        feedback, etc., provided on, or through the Application. The
        information, reports and Services are dependent on various assumptions,
        individual preferences, and other factors.
      </div>
      <div className="text pl-3">
        7.3. Risk on forward – looking terms. The Properties understand and
        acknowledge that past performance is not indicative of future results
        and there can be no assurance that the arrangement described on the
        Application will achieve its goals. Certain information contained herein
        constitutes “forward looking statements” which can be identified by the
        use of forward-looking terminology, because of various risks and
        uncertainties, actual events or results or actual performance may differ
        materially from the events, results or performance reflected or
        contemplated in such forward- looking statements. As a result, the
        Property should not rely on such forward-looking statements.
      </div>
      <div className="text pl-3">7.4. Risk on Transactions.</div>
      <div className="text pl-5">
        7.4.1. The Company only provides technological services to the
        Properties, without providing any advice, direction or undertaking any
        management of their property.
      </div>
      <div className="text pl-5">
        7.4.2. The Company only generates a User Website which connects
        Customers to the Properties.
      </div>
      <div className="text pl-5">
        7.4.3. The Company does not guarantee any results or bookings.
      </div>
      <div className="text">
        <b>8. Prohibited Use</b>
      </div>
      <div className="text pl-3">
        The Application may be used only for lawful purposes. The Company
        specifically prohibits any use of the Application for, and the Property
        agrees not to use the Application for, any unlawful and / or fraudulent
        purposes, including but not limited to the following purposes:
      </div>
      <div className="text pl-3">
        8.1.1. Modifying, publishing, storing, transmitting, distributing,
        displaying, performing, participating in the transfer or sale of, create
        derivative works on, or in any way exploiting, any of the copyrighted
        material contained on the Application, in whole or in part, without the
        Company’s prior written consent.
      </div>
      <div className="text pl-3">
        8.1.2. Subleasing, leasing, selling, assigning, transferring,
        distributing, renting, permitting concurrent use of, or granting other
        rights in the content and any material and documentation provided on the
        Application to any third party.
      </div>
      <div className="text pl-3">
        8.1.3. Providing use of the content in a computer service business,
        network, time sharing, interactive cable television, multiple CPU or
        multiple or concurrent user arrangement to users who are not
        individually licensed by the Company or otherwise exploiting any portion
        of, the use of or access to the content or the materials or
        documentation provided on the Application in contravention of this
        Agreement.
      </div>
      <div className="text pl-3">
        8.1.4. Defame, libel, disparage, threaten, harass or intimidate anyone
        or otherwise violate the personal, privacy, contractual, intellectual
        property or other rights of any person, including by the use of
        offensive comments related to race, national origin, gender, sexual
        preference or physical handicap.
      </div>
      <div className="text pl-3">
        8.1.5. Submit any content or material that falsely expresses or implies
        that such content or material is sponsored or endorsed by the Company.
      </div>
      <div className="text pl-3">
        8.1.6. Sell access to the Application or any part thereof other than
        through a mechanism expressly approved by the Company.
      </div>
      <div className="text pl-3">
        8.1.7. The Company may also place additional fair use restrictions
        including restrictions on concurrent connections and time of usage.
      </div>
      <div className="text pl-3">
        8.1.8. The Property further agrees to not use the Application or the
        User Website to host, display, upload, modify, publish, transmit, store,
        update or share any information that,— (i) belongs to another person and
        to which the Property does not have any right; (ii) is defamatory,
        obscene, pornographic, paedophilic, invasive of another’s privacy,
        including bodily privacy, insulting or harassing on the basis of gender,
        libellous, racially or ethnically objectionable, relating or encouraging
        money laundering or gambling, or otherwise inconsistent with or contrary
        to the Applicable Law; (iii) is harmful to any child; (iv) infringes any
        patent, trademark, copyright or other proprietary rights; (v) violates
        any Applicable Laws; (vi) deceives or misleads the addressee about the
        origin of the message or knowingly and intentionally communicates any
        information which is patently false or misleading in nature but may
        reasonably be perceived as a fact; (vii) impersonates another person,
        entity, or property; (viii) threatens the unity, integrity, defence,
        security or sovereignty of the Republic India, friendly relations with
        foreign States, or public order, or causes incitement to the commission
        of any cognisable offence or prevents investigation of any offence or is
        insulting another nation; (ix) contains software virus or any other
        computer code, file or program designed to interrupt, destroy or limit
        the functionality of any computer resource; (x) is patently false and
        untrue, and is written or published in any form, with the intent to
        mislead or harass a person, entity or agency for financial gain or to
        cause any injury to any person;
      </div>
      <div className="text pl-3">
        8.2. It is further clarified that in case of any non-compliance with
        either this Clause 8 or the terms of this Agreement, the Company may
        terminate the Property’s access or usage rights to the Application
        and/or suspend the User Website immediately or remove non-compliant
        information or both, as the case may be, at the Company’s sole
        discretion.
      </div>
      <div className="text pl-3">
        8.3. If, any information has been removed or access to which has been
        disabled as per Clause 8.2, the Company shall, without vitiating the
        evidence in any manner, preserve such information and associated records
        for 180 (one hundred and eighty) days for investigation purposes, or for
        such longer period as may be required by the court or by government
        agencies who are lawfully authorised.
      </div>
      <div className="text">
        <b>9. SECURITY</b>
      </div>
      <div className="text pl-3">
        9.1. The Property is prohibited from violating or attempting to violate
        the security of the Application, including, without limitation:
      </div>
      <div className="text pl-5">
        9.1.1. accessing data not intended for the Property or logging into an
        Account which the Property is not authorized to access,
      </div>
      <div className="text pl-5">
        9.1.2. attempting to probe, scan or test the vulnerability of a system
        or network or to breach security or authentication measures without
        proper authorization,
      </div>
      <div className="text pl-5">
        9.1.3. interfere with service to any user, host, or network.‍
      </div>
      <div className="text pl-3">
        9.2. The Property shall not misuse this Application by knowingly
        introducing viruses, trojans, worms, logic bombs or other materials that
        are malicious or technologically harmful (together “Viruses”). The
        Property must not attempt to gain unauthorized access to the
        Application, the server on which the Application is stored or any
        server, computer or database connected to the Application. The Property
        must not attack this Application via a denial-of-service attack.
        Further, the Property shall not interfere with or circumvent any
        security feature of the Application or any feature that restricts or
        enforces limitations on use of or access to the Application, such as
        probing or scanning the vulnerability of any system, network or breach.
      </div>
      <div className="text pl-3">
        9.3. By breaching the provisions of this Clause, the Property may be
        liable to be prosecuted under the Information Technology Act, 2000 and
        any other Applicable Law. The Company will report any such breach to the
        relevant law enforcement authorities, and the Company will co – operate
        with such authorities by disclosing the Property’s identity to them. In
        the event of such a breach, the Property’s rights to use this
        Application will cease immediately.
      </div>
      <div className="text pl-3">
        9.4. The Company will not be liable for any loss or damage caused by a
        denial-of-service attack or Viruses that may infect the Property’s
        computer equipment, computer programs, data or other proprietary
        material due to the Property’s use of this Application or to the
        Property downloading of any material posted on it, or on any Application
        linked to it.
      </div>
      <div className="text pl-3">
        9.5. The Property agrees to immediately report to the Company all
        incidents involving suspected or actual unauthorized access, disclosure,
        alteration, loss, damage, or destruction of data.
      </div>
      <div className="text pl-3">
        9.6. The Property shall not interfere with or disrupt (or attempt to
        interfere with or disrupt) access and enjoyment of the Application of
        other users or any host or network, including, without limitation,
        creating or transmitting unwanted electronic communications such as
        “spam” to other users, overloading, flooding or mail-bombing the
        Application, or scripting the creation of content in such a manner as to
        interfere with or create an undue burden on the Application.
      </div>
      <div className="text pl-3">
        9.7. The Property is prohibited from reverse engineering, decompiling,
        reverse assembling, modifying or attempting to discover or copy any
        software, source code or structure that the Application utilize to
        generate web pages or any software or other products or processes
        accessible through the Application.
      </div>
      <div className="text pl-3">
        9.8. All actions performed by any person using the Property’s Account
        shall be deemed to have been committed by the Property and the Property
        shall be liable for the same. Company reserves the right to
        suspend/terminate the Property’s Account at any time if it is found that
        the Property has been sharing the password with any unauthorized user.
      </div>
      <div className="text pl-3">
        9.9. The Company shall not knowingly deploy or install or modify
        technical configuration of the Application or become party to any act
        that may change or has the potential to change the normal course of
        operation of the Application than what it is supposed to perform thereby
        circumventing any Applicable Law. It is clarified that, notwithstanding
        anything in the Agreement, the Company may develop, produce, distribute
        or employ technological means for the purpose of securing the
        Application.
      </div>
      <div className="text">
        <b>10. INTELLECTUAL PROPERTY</b>
      </div>
      <div className="text pl-3">
        10.1. This Application is operated by and is the sole property of the
        Company. Any and all material on this Application, including images,
        illustrations, audio and video clips, trade names, proprietary
        information and knowledge, technology, databases, or rights with respect
        thereto necessary for Our business as is now being operated are
        protected by copyrights, trademarks, and other intellectual property
        rights that are owned by the Company or by other parties that have
        licensed such material to the Company, or such parties having ownership
        of content uploaded on the Application. The Property agrees that any and
        all material displayed on the Application is solely for the Property’s
        personal use and the Property shall not, whether directly or indirectly,
        copy, reproduce, republish, post, upload, transmit or distribute such
        material in any manner and through any media including by way of e-mail
        or other electronic means and the Property shall not assist any other
        person in doing so. Modification of the said materials or use of the
        materials on any other Application or networked computer environment or
        use of the materials for any purpose other than personal use is a
        violation of the said copyrights, trademarks and other intellectual
        proprietary rights, and is expressly prohibited. The Company grants to
        the Property a temporary, non-exclusive, revocable, non-transferable
        limited license to use the Application for the Property own use. All
        right, title, and interest in and to the Application (excluding content
        uploaded specifically by a the Property) is and will remain the
        exclusive property of the Company and/or its licensors.
      </div>
      <div className="text pl-3">
        10.2. The Property agrees that this Agreement, does not entitle the
        Property to any support, upgrades, updates, add-ons, patches,
        enhancements, or fixes for the Application (“Updates”). The Company may,
        however, occasionally provide automatic updates to the Application at
        its sole discretion (and without any advanced notification to the
        Property). Any such updates shall become part of the Services and
        subject to this Agreement.
      </div>
      <div className="text pl-3">
        10.3. From time to time, we test various aspects of our Application,
        including our website, user interfaces, service levels, plans, features,
        availability of content, delivery, and pricing, and we reserve the right
        to include the Property in or exclude the Property from these tests
        without notice.
      </div>
      <div className="text pl-3">
        10.4. Company shall have no obligation to monitor or enforce any
        intellectual property rights that may be associated with the content
        provided by the Property on the Application or the User Website.
      </div>
      <div className="text pl-3">
        10.5. If the Property is a copyright owner or an agent thereof and
        believes that any content on the Application infringes upon the
        Property’s rights, the Property may contact the below mentioned and
        provide details requested in regard to such infringement.
      </div>
      <div className="text pl-3">Grievance Redressal Officer(s): Anirudh</div>
      <div className="text pl-3">
        Contact Information: support@hotelzify.com
      </div>
      <div className="text pl-3">
        The Grievance Redressal Officer shall - (i) acknowledge the complaint
        within 24 (twenty four) hours and dispose off such complaint within a
        period of 15 (fifteen) days from the date of its receipt; (ii) receive
        and acknowledge any order, notice or direction issued by the appropriate
        government, any competent authority or a court of competent
        jurisdiction.
      </div>
      <div className="text">
        <b>11. TAXES</b>
      </div>
      <div className="text pl-3">
        11.1. The Property agrees to bear all applicable taxes, charges, cess
        etc. levied on the Services being rendered to the Property as notified
        by the relevant government authority from time to time, as per the
        Applicable Law, unless already included in the Fee.
      </div>
      <div className="text pl-3">
        11.2. The Property would be solely responsible for the compliance of all
        applicable laws including but not limited to applicable legislations
        regarding services tax, central, state or local levies with respect to
        payment of tax, duties, levies, charges, cess, etc. The tax collected by
        the Company as part of the room charges shall be remitted to the
        Property and it shall be sole responsibility of the Property to deposit
        the same to the concerned authority under the applicable law. Further,
        the Company shall not be liable to deposit such amount unless
        specifically mandated by law. In cases where the law requires the
        Company to deposit tax, the Property shall not be eligible to collect
        any applicable taxes from the Customer through the Company.
      </div>
      <div className="text pl-3">
        11.3. Further, the Company shall deduct/ collect/ withhold any tax to be
        levied under applicable tax laws, in such manner as may be prescribed,
        from the amount payable to the Property (i.e. the amount collected from
        the Customer and to be remitted to the Property ) and shall furnish
        appropriate documentation for the same.
      </div>
      <div className="text pl-3">
        11.4. All payments for Fee made by the Property to the Company under the
        Agreement would be subject to withholding tax as per the law applicable
        on the date of payment. The Party withholding the tax would be
        responsible for providing appropriate proof, certificate, documents,
        etc. to enable the other Party claim the benefit of the same.
      </div>
      <div className="text pl-3">
        11.5. The Parties would be responsible for their own tax assessments,
        audits, inquires, etc. and would keep the other Party indemnified from
        any additional tax demand arising out of the same.
      </div>
      <div className="text pl-3">
        11.6. The Property undertakes to provide the Company with necessary
        documents, as may be required by law from time to time, to prove the
        Property ’s compliance with the applicable tax laws with respect to this
        Agreement including its obligations to deposit the necessary taxes with
        the authorities. Any interest, penalties or recoveries from the Company
        by any authority on account of default by the Property will be solely
        borne by the Property on its own account.
      </div>
      <div className="text pl-3">
        11.7. The Property authorizes the Company to collect the taxes (under
        the applicable laws) on behalf of the Property from the Customers at the
        time of booking and remit the same to the Property for depositing the
        same with the governmental authorities.
      </div>
      <div className="text">
        <b>12. Third Party Payment Gateway</b>
      </div>
      <div className="text pl-3">
        12.1. In order to process payments, the Company uses Payment Gateway and
        may require the Property’s or Customer’s bank account details, credit
        card Information, etc, The Company shall not be liable or responsible in
        any manner whatsoever for any issues, claims or losses arising out of
        such a transaction on the Payment Gateway.
      </div>
      <div className="text">
        <b>13. SYSTEM LIMITATIONS AND FAILURES</b>
      </div>
      <div className="text">
        The Company uses internally developed systems for providing the Property
        access to and facilitating the Property’s use of the Application. These
        systems may encounter technical or other limitations, and computer and
        communications hardware systems might experience interruptions. Further,
        the Company continually enhances and improve these systems in order to
        accommodate the level of use of the Application. We may also add
        additional features and functionality to Application that might result
        in the need to develop or license additional technologies. Increased
        utilization of the Application or providing new features or
        functionality may cause unanticipated system disruptions, slower
        response times, degradation in levels of customer service, and delays in
        reporting accurate financial information. The Property agrees that the
        Company shall not be liable to the Property or to any third party
        claiming through the Property, for any such failures contemplated
        herein.
      </div>
      <div className="text">
        <b>14. PRIVACY AND CONFIDENTIALITY</b>
      </div>
      <div className="text pl-3">
        14.1. The Property’s use of the Application is governed by Our Privacy
        Policy, which can be located at <a href={pp}>privacy policy</a>{" "}
      </div>
      <div className="text pl-3">
        14.2. Please read the Privacy Policy to understand the Company’s
        information collection and usage practices. The Property may obtain
        certain confidential information, including without limitation,
        technical, contractual, product, pricing, business related functions,
        activities and services, customer lists, knowledge of customer needs and
        preferences, business strategies, marketing strategies, methods of
        operation, markets and other valuable information that should reasonably
        be understood as confidential (“Confidential Information”). The Property
        acknowledges and agrees to hold all Confidential Information in strict
        confidence. Title and all interests to all Confidential Information
        shall be vested in the Company. The Property’s obligations regarding
        Confidential Information will survive the termination of this Agreement.
        Upon such termination, the Property must stop forthwith using any
        Confidential Information to which the Property may have been exposed in
        due course of the Property’s use of the Application.
      </div>
      <div className="text pl-3">
        14.3. The Property agrees that the Property’s obligations under this
        Clause are necessary and reasonable in order to protect Our business and
        expressly agree that monetary damages would be inadequate to compensate
        for any breach of any covenant or agreement set forth herein.
        Accordingly, The Property agree and acknowledge that any such violation
        or threatened violation will cause irreparable harm and injury to
        Company and that, in addition to any other remedies that may be
        available, in law, equity or otherwise, Company shall be entitled to
        obtain injunctive relief against the threatened breach of the terms of
        this Clause or the continuation of any such breach.
      </div>
      <div className="text pl-3">
        14.4. The restrictions in this Clause shall not apply to disclosure of
        Confidential Information by either party if and to the extent the
        disclosure is:
      </div>
      <div className="text pl-5">
        14.4.1. required by the Applicable Law of any jurisdiction,
      </div>
      <div className="text pl-5">
        14.4.2. required by any supervisory or regulatory or governmental body
        to which the relevant party is subject or submits, wherever situated,
        whether or not the requirement for disclosure has the force of law,
      </div>
      <div className="text pl-5">
        14.4.3. made to employees and representatives on a need to know basis,
        provided that such persons are required to treat such information as
        confidential through written agreement in terms which are no less strict
        than this Clause.‍
      </div>
      <div className="text pl-3">
        14.5. Provided that, restriction in this Clause 14 shall not apply to
        disclosures of Confidential Information made by the Company to its
        shareholders, directors, managers, potential investors and/or
        Affiliates. Provided further that Company may disclose the Confidential
        Information to third party vendors and customers or pursuant to
        partnering arrangements without the restriction of this Clause 14
        provided that such persons are required to treat such information as
        confidential through written agreement in terms which are no less strict
        than this Paragraph.
      </div>
      <div className="text">
        <b>15. PRIVACY IN EEA</b>
      </div>
      <div className="text pl-3">
        15.1. In some regions, such as the European Economic Area, you have
        rights that allow you greater access to and control over your Personal
        Data. You may review, change, or terminate your account at any time. In
        some regions (like the European Economic Area), you have certain rights
        under applicable data protection laws. These may include the right (i)
        to request access and obtain a copy of your Personal Data, (ii) to
        request rectification or erasure; (iii) to restrict the processing of
        your Personal Data; and (iv) if applicable, to data portability. In
        certain circumstances, you may also have the right to object to the
        processing of your Personal Data. To make such a request, please use the
        contact us at <a href="https://hotelzify.com">hotelzify.com</a>. We will
        consider and act upon any request in accordance with applicable data
        protection laws. You have the right to withdraw your consent at any
        time. Please note however that this will not affect the lawfulness of
        the processing before its withdrawal. If you are resident in the
        European Economic Area and you believe we are unlawfully processing your
        Personal Data, you also have the right to complain to your local data
        protection supervisory authority. You can find their contact details
        here <a href="https://hotelzify.com">hotelzify.com</a>.
      </div>
      <div className="text">
        <b>16. LINKS TO THIRD PARTY APPLICATION AND TOOLS</b>
      </div>
      <div className="text pl-3">
        16.1. The Application may contain link/s to various other websites.
        These links are provided solely for the Property’s convenience and
        benefit. Wherever such link/s lead to websites which are not owned by or
        belong to Company, the Company shall not be responsible for the content,
        products and services provided on such linked sites. The Company does
        not warrant or make any representations regarding the correctness or
        accuracy of the content on such websites. If the Property decides to
        access such linked websites, the Property does so at the Property’s own
        risk. The Company does not in any way endorse the linked websites.
      </div>
      <div className="text pl-3">
        16.2. Similarly, this Application can be made accessible through a link
        created by other websites. Access to this Application through such
        link/s shall not mean or be deemed to mean that the objectives, aims,
        purposes, ideas, concepts of such other websites or their aim or purpose
        in establishing such link/s to this Application are necessarily the same
        or similar to the idea, concept, aim or purpose of Our Application or
        that such links have been authorized by the Company. The Company is not
        responsible for any representation/s of such other websites while
        affording such link and no liability can arise upon the Company
        consequent to such representations, its correctness or accuracy. In the
        event that any link/s afforded by any other website/s derogatory in
        nature to the objectives, aims, purposes, ideas and concepts of this
        Application, is utilized to visit this Application and such event is
        brought to the notice or is within the knowledge of the Company, civil
        or criminal remedies as may be appropriate shall be invoked.
      </div>
      <div className="text">
        <b>17. COOKIE POLICY</b>
      </div>
      <div className="text pl-3">
        17.1. The Application may use cookies. By using the Application and
        agreeing to this Agreement, the Property consents to our use of cookies
        in accordance with the terms of our Privacy Policy.
      </div>
      <div className="text">
        <b>18. Disclaimer of Warranties</b>
      </div>
      <div className="text">
        THE SERVICE IS PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
        IMPLIED. USE OF THE SERVICE IS AT PROPERTY’S SOLE RISK. COMPANY DOES NOT
        WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED OR ERROR FREE, NOR DOES
        COMPANY MAKE ANY WARRANTY AS TO ANY RESULTS THAT MAY BE OBTAINED BY USE
        OF THE SERVICE. COMPANY MAKES NO OTHER WARRANTIES, EXPRESS OR IMPLIED,
        INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY
        OR FITNESS FOR A PARTICULAR PURPOSE, IN RELATION TO THE SERVICE.
      </div>
      <div className="text">
        <b>19. LIMITATION OF LIABILITY</b>
      </div>
      <div className="text pl-3">
        19.1. THE DOCUMENTS AND GRAPHICS ON THIS APPLICATION COULD INCLUDE
        TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY
        ADDED TO THE INFORMATION HEREIN. THE COMPANY MAY MAKE IMPROVEMENTS
        AND/OR CHANGES HEREIN AT ANY TIME. THE COMPANY MAKE NO REPRESENTATIONS
        ABOUT THE ACCURACY OF THE INFORMATION CONTAINED IN THE DOCUMENTS AND
        GRAPHICS ON THIS APPLICATION FOR ANY PURPOSE. ALL DOCUMENTS AND GRAPHICS
        ARE PROVIDED “AS IS” AND “AS AVAILABLE”. THE COMPANY HEREBY DISCLAIMS
        ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, INCLUDING
        ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR
        ANY PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. IN NO EVENT, THE
        COMPANY AND/OR THEIR LICENSORS/ SUPPLIERS SHALL BE LIABLE TO ANY PARTY
        FOR ANY DIRECT, INDIRECT, SPECIAL OR OTHER CONSEQUENTIAL DAMAGES FOR ANY
        USE OF THE APPLICATION, THE INFORMATION, OR ON ANY OTHER HYPERLINKED WEB
        SITE, INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS
        INTERRUPTION, LOSS OF PROGRAMS OR OTHER DATA ON THE PROPERTY INFORMATION
        HANDLING SYSTEM OR OTHERWISE, EVEN IF THE COMPANY IS EXPRESSLY ADVISED
        OF THE POSSIBILITY OF SUCH DAMAGES.
      </div>
      <div className="text pl-3">
        19.2. IN NO EVENT SHALL COMPANY, ITS DIRECTORS, OFFICERS, EMPLOYEES,
        AFFILIATES, AGENTS OR CONTRACTORS BE LIABLE FOR ANY COMPENSATORY,
        DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, LOSS OF
        DATA, INCOME OR PROFIT, LOSS OF OR DAMAGE TO PROPERTY, OR ANY CLAIMS OF
        THE PROPERTY OR CUSTOMERS OR OTHER THIRD PARTIES WHATSOEVER WITH RESPECT
        TO THE APPLICATION OR USER WEBSITE REGARDLESS OF THE LEGAL THEORY ON
        WHICH THE CLAIM IS BASED, INCLUDING, WITHOUT LIMITATION, ANY DAMAGES
        THAT RESULT FROM ANY MISTAKE, OMISSION, VIRUS, DELAY OR INTERRUPTION IN
        OPERATION OR SERVICE OR FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING
        FROM AN ACT OF GOD, COMMUNICATIONS FAILURE, THEFT OR OTHERWISE. THE
        COMPANY SHALL NOT BE LIABLE FOR ANY LOSSES OR DAMAGES WHATSOEVER
        RESULTING FROM ANY FAILURE OF THE INTERNET.
      </div>
      <div className="text pl-3">
        19.3. THE COMPANY DOES NOT GUARANTEE THAT ANY CONTENT, INFORMATION,
        SOFTWARE OR OTHER MATERIAL ACCESSIBLE THROUGH THE APPLICATION WILL BE
        FREE OF VIRUSES, “WORMS”, “TROJAN HORSES”, OR THEIR HARMFUL COMPONENTS.
        THE COMPANY’S ENTIRE LIABILITY AND THE PROPERTY SOLE AND EXCLUSIVE
        REMEDY WITH RESPECT TO THE USE OF ANY SERVICES PROVIDED BY THE COMPANY
        SHALL BE CANCELLATION OF THE PROPERTY ACCOUNT.
      </div>
      <div className="text">
        <b>20. MISCELLANEOUS</b>
      </div>
      <div className="text pl-3">
        20.1. Governing Law. All disputes arising out of this Agreement and any
        disclaimers on the Application shall be subject to the sole and
        exclusive jurisdiction of the courts of New Delhi, India.
      </div>
      <div className="text pl-3">
        20.2. Dispute Resolution. Any dispute, controversy or claim arising out
        of or in relation to this Agreement or the breach, termination or
        invalidity thereof, shall be settled by arbitration in accordance with
        the provisions of the Arbitration and Conciliation Act, 2019. The
        arbitrator shall be appointed by Company. The place of arbitration shall
        be New Delhi and any award whether interim or final, shall be made, and
        shall be deemed for all purposes between the Parties to be made, in New
        Delhi. The arbitral procedure shall be conducted in the English language
        and any award or awards shall be rendered in English. The procedural law
        of the arbitration shall be Indian law. The award of the arbitral
        tribunal shall be final, conclusive and binding upon the Parties.
      </div>
      <div className="text pl-3">
        20.3. Assignment. The Property cannot assign or otherwise transfer the
        Property’s rights or obligations this Agreement to any affiliate and /
        or third party without the prior consent of Company.
      </div>
      <div className="text pl-3">
        20.4. Independent Contractors. The Parties and their respective
        personnel, are and shall be independent contractors and neither Party by
        virtue of this Agreement shall have any right, power or authority to act
        or create any obligation, express or implied, on behalf of the other
        party.
      </div>
      <div className="text pl-3">
        20.5. Severability. If any provision of this Agreement is held to be
        illegal, invalid, or unenforceable under any present or future
        Applicable Law (a) such clause or part thereof shall be fully severable;
        and (b) the remaining provisions of this Agreement shall remain in full
        force and effect and shall not be affected by the illegal, invalid, or
        unenforceable provision or by its severance here from to the extent
        permissible under Applicable Law.
      </div>
      <div className="text pl-3">
        20.6. Notices. All notices and other communications required or
        permitted hereunder to be given to a party will be in writing, in the
        English language, and will be sent by way of e-mail, or mailed by
        prepaid internationally – recognized courier or otherwise delivered by
        hand or by messenger, addressed to such party’s address as set forth
        below or at such other address as the party will have furnished to the
        other party in writing in accordance with this provision. For COMPANY:
        support@hotelzify.com For the Property: Such address provided by the
        Property on the Registration.
      </div>
      <div className="text pl-3">
        20.7. Changes to Terms of Use. The Company reserves the right to update
        (change, modify, add and/or delete) this Agreement from time to time, at
        the sole discretion of the Company. When we update this Agreement, we
        will intimate the Property of the amendments on the Property’s email
        address and / or on the Application. If the Property does not agree to
        the amendments, please do not use the Application any further. The
        Property’s continued use of the Application following the posting of
        changes will mean that the Property accept and agree to the revisions.
        The Company has the right to include more services under the ambit of
        this Agreement, from time to time. The Property shall be updated of such
        addition of products by way of a notification and / or an electronic
        mail.
      </div>
      <div className="text pl-3">
        20.8. Indemnity. THE PROPERTY AGREES TO INDEMNIFY AND HOLD HARMLESS THE
        COMPANY, ITS AFFILIATES, THIRD – PARTIES ASSOCIATED WITH COMPANY AND
        THEIR RESPECTIVE SHAREHOLDERS, OFFICERS, DIRECTORS, AGENTS, AND
        EMPLOYEES, FROM ANY LOSSES, LIABILITIES, DAMAGES, COSTS AND EXPENSES,
        CLAIMS OR DEMAND, MADE BY ANY THIRD PARTY INCLUDING THE CUSTOMER ARISING
        OUT OF; (A) THE PROPERTY’S BREACH OR NON-PERFORMANCE OF THE TERMS OF
        THIS AGREEMENT OR, (B) THE DOCUMENTS INCORPORATED BY REFERENCE BETWEEN
        COMPANY AND THE PROPERTY OR, (C) THE PROPERTY’S VIOLATION OF ANY
        APPLICABLE LAWS OR, (D) THE RIGHTS OF A THIRD PARTY.
      </div>
      <div className="text pl-3">
        20.9. Scope. This Agreement shall be read in consonance with any other
        agreement entered between the Property and Company. Notwithstanding
        anything to the contrary in this Agreement, in the event of a
        discrepancy between this Agreement and such agreement between the
        Property and Company, the provisions of such Agreement will prevail to
        the extent of the discrepancy.
      </div>
      <div className="text">
        <b>21. Termination</b>
      </div>
      <div className="text">
        This Agreement is effective upon Property’s acceptance as set forth
        herein and shall continue in full force until terminated. The Property
        may terminate this Agreement for any reason upon thirty (30) days prior
        notice to Company. The Company reserves the right, in its sole
        discretion and without notice, at any time and for any reason, to: (a)
        remove or disable access to all or any portion of the Service; (b)
        suspend Property’s access to or use of all or any portion of the
        Service; and (c) terminate this Agreement.
      </div>
    </div>
  );
};

export default Terms;
