import { createContext, useContext, useEffect, useState } from "react";
import Router from "./router";
import {
  getConversationsApi,
  getEnabledHotelsApi,
} from "./services/apiServices";
import { toast } from "react-toastify";
const HotelContext = createContext();
function App() {
  const [value, setValue] = useState();
  const [hotels, setHotels] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState();
  const [selectedChat, setSelectedChat] = useState();
  const [conversations, setConversations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [isAI, setIsAI] = useState(true);

  const pageSize = 100;

  // useEffect(() => {
  //   fetchHotels();
  // }, []);

  useEffect(() => {
    if (selectedHotel) {
      console.log("useeff?", selectedHotel);
      fetchConversations(selectedHotel);
    }
  }, [selectedHotel]);

  const fetchHotels = async (hotelId) => {
    try {
      const response = await getEnabledHotelsApi(hotelId);
      console.log("Conversations:", response.data.enabledHotels);
      setHotels(response.data.enabledHotels);
      setSelectedChat(null);
      setCurrentPage(1);
    } catch (error) {
      console.error("Error fetching conversations:", error);
      toast.error("Failed to fetch conversations. Please try again later.");
    }
  };
  const fetchConversations = async (hotelId) => {
    console.log("here in appjs?");
    if (!hotelId) {
      console.error(
        "Hotel ID is null or undefined, cannot fetch conversations."
      );
      return; // Do not proceed if hotelId is null
    }

    try {
      setLoading(true); // Set loading to true while fetching
      const response = await getConversationsApi(
        hotelId,
        currentPage,
        pageSize
      );

      if (response.status === 200 && Array.isArray(response.data)) {
        setConversations(response.data);
        console.log("Fetched conversations:", response.data);

        const totalItems = response.data.length;
        setTotalPages(Math.ceil(totalItems / pageSize));
      } else {
        console.error("Unexpected API response structure:", response);
      }

      setLoading(false); // Set loading to false after fetching
    } catch (error) {
      console.error("Error fetching conversations:", error);
      setLoading(false); // Set loading to false even in case of error
    }
  };

  const toggleIsBotActive = (chatId) => {
    setConversations((prevConversations) => {
      return prevConversations.map((chat) =>
        chat._id === chatId
          ? { ...chat, isBotActive: !chat.isBotActive } // Toggle isBotActive
          : chat
      );
    });
  };
  return (
    <>
      <HotelContext.Provider
        value={{
          value,
          setValue,
          hotels,
          setHotels,
          selectedHotel,
          setSelectedHotel,
          selectedChat,
          setSelectedChat,
          conversations,
          setConversations,
          isAI,
          setIsAI,
          toggleIsBotActive,
        }}
      >
        <Router />
      </HotelContext.Provider>
    </>
  );
}
export const useHotelContext = () => {
  return useContext(HotelContext);
};
export default App;
