import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useCallback,
} from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import {
  Drawer,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
} from "@mui/material";
import SimpleBar from "simplebar-react";
import Markdown from "react-markdown";
import {
  Icon,
  Image,
  MediaGroup,
  Media,
  MediaText,
  Message,
  ChatList,
  CustomDropdownMenu,
  CustomDropdownToggle,
  ChatMedia,
} from "../../../components";

import {
  messageToGuestApi,
  getMessagesApi,
  toggleChatModeApi,
  markReadApi,
  getConversationByIdApi,
  postAnnotationApi,
} from "../../../services/apiServices";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import { useHotelContext } from "../../../App";
import dayjs from "dayjs";
import { Break } from "../../../utilities";
import classNames from "classnames";
import { TbWorldSearch } from "react-icons/tb";
import { SiWhatsapp } from "react-icons/si";
import { trackEvent } from "../../../analytics";

const pageSize = 12;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ChatMessageScreen = ({
  selectedChat,
  messages,
  setMessages,
  setIsAI,
  isAI,
  onBack,
  loadingMessages,
}) => {
  const { toggleIsBotActive } = useHotelContext();
  const [message, setMessage] = useState("");
  // const [isAI, setIsAI] = useState(true);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const chatEndRef = useRef(null);
  const simpleBarRef = useRef(null);
  const observer = useRef();
  const [isScrollLoading, setIsScrollLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [sidebar, setSidebar] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [liked, setLiked] = useState(new Map());
  const [disliked, setDisliked] = useState(new Map());
  const [feedbackVisible, setFeedbackVisible] = useState(new Map());
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const setLikedMap = (k, v) => {
    setLiked(new Map(liked.set(k, v)));
  };
  const setDislikedMap = (k, v) => {
    setDisliked(new Map(disliked.set(k, v)));
  };
  const setFeedbackMap = (k, v) => {
    setFeedbackVisible(new Map(feedbackVisible.set(k, v)));
  };
  const handleCloseDrawer = (msg) => {
    setFeedbackMap(msg._id, false);
  };
  const lastPageElementRef = useRef();
  const chatProfileClasses = classNames({
    "nk-chat-profile": true,
    "show-profile": sidebar,
    "toggle-collapsed": window.innerWidth < Break.xxl,
  });

  const lastElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        trackEvent("fetching-older-messages");
        console.log(
          "hasmore",
          entries[0].isIntersecting,
          hasMore,
          isScrollLoading
        );
        if (entries[0].isIntersecting && hasMore && !isScrollLoading) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, isScrollLoading]
  );

  useEffect(() => {
    if (page === 1) {
      scrollToBottom();
    } else {
      lastPageElementRef?.current?.scrollIntoView();
    }
  }, [messages, selectedChat]);
  useEffect(() => {
    fetchMessages();
  }, [page]);

  useEffect(() => {
    fetchConversationDetails();
  }, [isAI]);

  const handleChatToggle = async () => {
    setSidebar(!sidebar);

    const resp = await fetchConversationDetails();
    if (resp) {
      setUserDetails(resp);
    }
  };

  const fetchConversationDetails = async () => {
    if (!selectedChat) return;

    try {
      const conversationDetailsResponse = await getConversationByIdApi(
        selectedChat._id
      );

      if (
        conversationDetailsResponse.status === 200 &&
        conversationDetailsResponse.data
      ) {
        const updatedConversation = conversationDetailsResponse.data;

        setIsAI(updatedConversation.isBotActive);
        return updatedConversation;
      } else {
        console.error(
          "Unexpected API response structure:",
          conversationDetailsResponse
        );
      }
    } catch (error) {
      console.error("Error fetching conversation details:", error);
    }
  };

  const fetchMessages = async () => {
    try {
      setIsScrollLoading(true);
      const obj = {
        // hotelId:selectedChat?.hotelId
        conversationId: selectedChat?._id,
        page: page,
      };
      const messagesResponse = await getMessagesApi(
        obj.conversationId,
        obj.page,
        pageSize
      );
      if (
        messagesResponse.status === 200 &&
        Array.isArray(messagesResponse.data)
      ) {
        if (obj.page === 1) {
          setMessages(messagesResponse.data);
        } else {
          setMessages([...messages, ...messagesResponse.data]);
        }
        console.log(
          "hasmore",
          messagesResponse.data,
          messagesResponse.data.length
        );
        setHasMore(messagesResponse.data.length >= 12);
      } else {
        console.error("Error in message response:", messagesResponse);
        setMessages([]);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
      setMessages([]); // Handle any API or network errors
      setHasMore(false);
    } finally {
      setIsScrollLoading(false);
    }
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    gap: "5px",
    backgroundColor: "#EFEFEF",
    borderRadius: "50px",
    // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    width: isLargeScreen ? "auto" : "auto",
    margin: "0 10px",
    height: isLargeScreen ? "50px" : "",
  };
  const scrollToBottom = () => {
    if (simpleBarRef.current) {
      const scrollElement = simpleBarRef.current.getScrollElement();
      scrollElement.scrollTop = scrollElement.scrollHeight;
    }
  };

  useEffect(() => {
    if (selectedChat) {
      scrollToBottom();
      setHasMore(true);
      setPage(1);
    }
  }, [selectedChat]);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024); // lg breakpoint (1024px)
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize); // Update on window resize

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const chatPanel = document.querySelector(".nk-chat-panel");
    if (chatPanel) {
      chatPanel.scrollTop = chatPanel.scrollHeight;
    }
  }, [selectedChat]);

  const handleSendMessage = async (e) => {
    trackEvent("clicked-send-message", message);
    e.preventDefault();

    if (!message.trim()) return;

    try {
      if (isAI) {
        trackEvent("switched-to-agent");
        handleToggleChatMode();
      }
      const response = await messageToGuestApi({
        sessionId: selectedChat.sessionId,
        message: message,
        hotelId: 3480,
      });

      if (response.status === 200) {
        const messagesResponse = await getMessagesApi(selectedChat._id);
        if (
          messagesResponse.status === 200 &&
          Array.isArray(messagesResponse.data)
        ) {
          setMessages(messagesResponse.data);
        } else {
          console.error("error in message resp:", messagesResponse);
          setMessages([]);
        }
      } else {
        console.error("Error sending message:", response);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setMessage("");
    }
  };

  const handleBookingNavigate = (bookingId) => () => {
    trackEvent("navigate-to-booking-link", bookingId);
    if (bookingId) {
      window.open(`https://bookmystay.io/checkout/${bookingId}`, "_blank");
    }
  };

  const handleToggleChatMode = async (agent) => {
    trackEvent("switched-chat-mode", agent);
    try {
      const sessionId = selectedChat.sessionId;

      const response = await toggleChatModeApi(agent, sessionId);

      if (response.status === 200) {
        setIsAI(!isAI);
        toggleIsBotActive(selectedChat._id);
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("Error toggling chat mode:", error);
    }
  };

  if (!selectedChat) {
    return <div className="nk-chat-body">Select a chat to start messaging</div>;
  }

  const handleThumbsUp = async (msg) => {
    trackEvent("clicked-thumbs-up", msg);
    setLikedMap(msg._id, true);
    setDislikedMap(msg._id, false);
    const obj = {
      messageId: msg._id,
      isLiked: true,
    };
    const response = await postAnnotationApi({
      messageId: msg._id,
      isLiked: true,
    });
    console.log("thumbsup", obj);
    if (response.status === 200) {
      toast.success("Thank you for your feedback");
    }
  };

  const handleThumbsDown = async (msg) => {
    trackEvent("clicked-thumbs-down", msg);
    setFeedbackMap(msg._id, true);
    setLikedMap(msg._id, false);
  };

  const handleSubmitFeedback = async (msg) => {
    try {
      const response = await postAnnotationApi({
        messageId: msg._id,
        isDisliked: true,
        isLiked: false,
        annotationMessage: feedbackMessage,
      });
      const obj = {
        messageId: msg._id,
        isDisliked: true,
        annotationMessage: feedbackMessage,
      };
      console.log("thumb", obj);
      trackEvent("submitted-feedback", obj?.annotationMessage);

      if (response.status === 200) {
        setFeedbackMap(msg._id, false);
        setFeedbackMessage("");
        setDislikedMap(msg._id, true);
        setLikedMap(msg._id, false);
        toast.success("Thank you for the feedback");
        handleCloseDrawer(msg);
      }
    } finally {
    }
  };

  return (
    <div className="nk-chat-body show-chat">
      {loadingMessages ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="nk-chat-body show-chat">
          <div className="nk-chat-head">
            <ul className="nk-chat-head-tools me-1 ms-n1 d-md-none">
              <li>
                <Button
                  size="sm"
                  variant="zoom"
                  className="btn-icon toggle-close-chat"
                  onClick={onBack}
                >
                  <Icon
                    name="arrow-left"
                    style={{ color: "pink", height: "100px" }}
                  />
                </Button>
              </li>
            </ul>
            <MediaGroup>
              <Media
                shape="circle"
                middle
                style={{
                  background: "#D4C7EE",
                  width: "60px",
                  height: "60px",
                }}
              >
                {selectedChat?.channel === "web" && (
                  <TbWorldSearch size={30} style={{ color: "#aa90de" }} />
                )}
                {selectedChat?.channel === "whatsapp" && (
                  <SiWhatsapp size={30} style={{ color: "#aa90de" }} />
                )}
              </Media>
              <MediaText>
                <span className="title">
                  <span>
                    {selectedChat.user?.firstName ||
                      selectedChat.user?.first_name ||
                      "Unknown"}
                  </span>{" "}
                  <span>
                    {selectedChat.user?.lastName ||
                      selectedChat.user?.last_name ||
                      ""}
                  </span>
                </span>
                <span className=" smaller">
                  {/* <span>Active</span> */}
                  <span>{selectedChat?.hotel?.name}</span>
                </span>
              </MediaText>
            </MediaGroup>
            <ul className="nk-chat-head-tools gap gx-md-2 me-n2">
              {/* Toggle Button for AI/Person */}

              <div style={containerStyle}>
                <div
                  style={{
                    border: isAI ? "2px solid blue" : "none",
                    backgroundColor: isAI ? "white" : "",
                    color: isAI ? "blue" : "",
                    borderRadius: isAI ? "50px" : "",
                    padding: isAI && isLargeScreen ? "5px 10px" : "",
                    boxShadow: isAI ? "0 4px 8px rgba(0, 0, 0, 0.1)" : "",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    // height: "20px",
                  }}
                  onClick={() => handleToggleChatMode("AI")}
                >
                  <Button
                    size="md"
                    variant="zoom"
                    className="btn-icon text-light"
                    style={{ height: "20px" }}
                  >
                    <Icon
                      name="reddit"
                      size={25}
                      style={{ color: isAI ? "blue" : "grey" }}
                    />
                  </Button>
                  {isLargeScreen && <span>AI bot</span>}
                </div>

                <div
                  style={{
                    border: !isAI ? "2px solid blue" : "",
                    backgroundColor: !isAI ? "White" : "",
                    borderRadius: !isAI ? "50px" : "",
                    color: !isAI ? "blue" : "",
                    padding: !isAI && isLargeScreen ? "5px 15px" : " ",
                    boxShadow: !isAI ? "0 4px 8px rgba(0, 0, 0, 0.1)" : "",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    // height: "20px",
                  }}
                  onClick={() => handleToggleChatMode("Owner")}
                >
                  <Button
                    size="md"
                    variant="zoom"
                    className="btn-icon text-light"
                    style={{ height: "20px" }}
                  >
                    <Icon
                      name="user"
                      size={25}
                      style={{ color: !isAI ? "blue" : "grey" }}
                    />
                  </Button>
                  {isLargeScreen && <span>Human Agent</span>}
                </div>
              </div>

              {(selectedChat?.user?.email || selectedChat?.user?.mobile) && (
                <li>
                  <Button
                    size="md"
                    variant="zoom"
                    className={`btn-icon toggle-chat-profile ${
                      sidebar ? "active" : "inActive"
                    }`}
                    onClick={handleChatToggle}
                  >
                    <Icon name="info-fill" style={{ fontSize: "1.6em" }} />
                  </Button>
                </li>
              )}
            </ul>
          </div>
          <SimpleBar
            className="nk-chat-panel"
            style={{ height: "calc(10vh - 100px)", paddingBottom: "5px" }}
            ref={simpleBarRef}
          >
            <div
              className="nk-message-wrap"
              style={{ maxHeight: "100%", overflowY: "auto" }}
            >
              {messages && messages.length > 0 ? (
                messages.map((msg, i) => (
                  <Message
                    key={msg._id} //  msg._id for key ->conv id
                    avatar={msg.role !== "AI" ? selectedChat.avatar : null}
                    isYou={msg.role === "user"}
                    isMe={msg.role === "AI" || msg.role === "Owner"}
                  >
                    <ul className="nk-message-bubbles">
                      <li>
                        <div
                          className="nk-message-bubble"
                          ref={
                            i === messages.length - pageSize
                              ? lastPageElementRef
                              : null
                          }
                        >
                          <div className="nk-message-text">
                            <Markdown>{msg.message}</Markdown>
                          </div>{" "}
                          <ul className="nk-message-meta">
                            <li>
                              {dayjs(msg.timestamp).format(
                                "ddd D MMM, hh:mm A"
                              )}

                              {/* {dayjs(msg.timestamp).format(
                                "MMM D, YYYY hh:mm A"
                              )} */}

                              {/* {new Date(msg.timestamp)
                                .toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                })
                                .replace(":", ".")} */}
                            </li>
                          </ul>
                        </div>

                        <Message.Actions />
                      </li>

                      {msg?.function_call?.output?.data?.bookingId && (
                        <button
                          style={{
                            marginTop: "10px",
                            marginLeft: "auto",
                            color: "blue",
                            border: "none",
                            borderBottom: "1px dotted blue",
                          }}
                          onClick={handleBookingNavigate(
                            msg?.function_call?.output?.data?.bookingId
                          )}
                        >
                          View booking voucher
                        </button>
                      )}

                      {/* {msg.role !== "user" && msg.role !== "guest" && (
                        <div
                          style={{
                            marginTop: "5px",
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "10px",
                          }}
                        >
                          <button
                            style={{ border: "none", background: "none" }}
                            onClick={() => handleThumbsUp(msg)}
                          >
                            <Icon
                              name="thumbs-up"
                              style={{
                                cursor: "pointer",
                                color:
                                  msg?.annotations?.isLiked ||
                                  liked.get(msg._id)
                                    ? "blue"
                                    : "black",
                                // color: liked.get(msg._id) ? "blue" : "black",
                              }}
                            />
                          </button>
                          <button
                            style={{ border: "none", background: "none" }}
                            onClick={() => handleThumbsDown(msg)}
                          >
                            <Icon
                              name="thumbs-down"
                              style={{
                                cursor: "pointer",
                                color:
                                  msg?.annotations?.isDisliked ||
                                  disliked.get(msg._id)
                                    ? "blue"
                                    : "black",
                              }}
                            />
                          </button>
                        </div>
                      )} */}
                      {msg?.role !== "user" && feedbackVisible.get(msg._id) && (
                        <Dialog
                          open={feedbackVisible.get(msg._id)}
                          onClose={() => handleCloseDrawer(msg)}
                          TransitionComponent={Transition}
                          keepMounted
                          aria-describedby="alert-dialog-slide-description"
                          fullWidth={true}
                          style={{ margin: "10px" }}
                        >
                          <div
                            onClick={() => handleCloseDrawer(msg)}
                            style={{
                              marginLeft: "auto",
                              cursor: "pointer",
                              padding: "2px 20px",
                              fontWeight: "bold",
                            }}
                          >
                            x
                          </div>
                          <div style={{ padding: "10px" }}>
                            <h4>
                              Sorry for your inconvenience , please let us know
                              What could have been done better?
                            </h4>
                            <p>{msg.message}</p>
                          </div>
                          <div
                            className="nk-chat-input"
                            style={{ margin: "10px" }}
                          >
                            <div className="nk-chat-input-field">
                              <div className="form-control-wrap">
                                <Form.Control
                                  size="md"
                                  type="text"
                                  placeholder="Enter your feedback..."
                                  className="form-control-plaintext"
                                  value={feedbackMessage}
                                  onChange={(e) =>
                                    setFeedbackMessage(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="nk-chat-input-send">
                              <Button
                                type="submit"
                                size="sm"
                                variant="zoom"
                                className="btn-icon text-light"
                                onClick={() => handleSubmitFeedback(msg)}
                              >
                                <Icon name="send" />
                              </Button>
                            </div>
                          </div>
                        </Dialog>
                      )}
                    </ul>
                  </Message>
                ))
              ) : (
                <div className="nk-message-no-messages">
                  No messages yet. Start the conversation!
                </div>
              )}
              <div
                ref={lastElementRef}
                style={{ border: "5px solid transparent", textAlign: "center" }}
              >
                {isScrollLoading ? (
                  <div
                    style={{
                      width: "100%",
                      height: "80vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Loader />{" "}
                  </div>
                ) : (
                  // "No more messages"
                  ""
                )}
              </div>
              <div ref={chatEndRef} />
            </div>
          </SimpleBar>

          <div className="nk-chat-form">
            <Form onSubmit={handleSendMessage}>
              <div className="nk-chat-input">
                <div className="nk-chat-input-field">
                  <div className="form-control-wrap">
                    <Form.Control
                      size="md"
                      type="text"
                      placeholder="Type your message..."
                      className="form-control-plaintext"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                </div>
                <div className="nk-chat-input-send">
                  <Button
                    type="submit"
                    size="sm"
                    variant="zoom"
                    className="btn-icon text-light"
                  >
                    <Icon name="send" />
                  </Button>
                </div>
              </div>
              {window.innerWidth < Break.xxl && sidebar && (
                <div onClick={handleChatToggle} className="nk-overlay"></div>
              )}
              <SimpleBar
                className={chatProfileClasses}
                data-overlay
                style={{ width: "300px" }}
              >
                <div className="nk-aside-content py-4 border-bottom border-light position-relative">
                  <MediaGroup column className="text-center py-2">
                    <Media
                      size="big"
                      shape="circle"
                      middle
                      style={{
                        background: "#D4C7EE",
                        width: "60px",
                        height: "60px",
                      }}
                    >
                      {userDetails?.channel === "web" && (
                        <TbWorldSearch size={30} style={{ color: "#aa90de" }} />
                      )}
                      {userDetails?.channel === "whatsapp" && (
                        <SiWhatsapp size={30} style={{ color: "#aa90de" }} />
                      )}
                    </Media>
                    <MediaText>
                      <h3 className="mb-1">
                        <span>
                          {userDetails?.user?.first_name || "unknown"}{" "}
                        </span>{" "}
                        {userDetails?.user?.last_name || ""}
                      </h3>
                      {userDetails?.user?.mobile && (
                        <span>
                          <span>
                            Mobile:<strong>{userDetails?.user?.mobile}</strong>
                          </span>
                        </span>
                      )}

                      {userDetails?.user?.email && (
                        <span>
                          <span>
                            Email: <strong>{userDetails?.user?.email}</strong>
                          </span>
                        </span>
                      )}
                    </MediaText>
                  </MediaGroup>
                  <Dropdown className="position-absolute top-0 start-0 p-4 ">
                    <Dropdown.Toggle
                      size="sm"
                      as={CustomDropdownToggle}
                      className="btn btn-sm btn-zoom btn-icon"
                    >
                      <button
                        style={{ border: "none", background: "transparent" }}
                        onClick={handleChatToggle}
                      >
                        <Icon name="cross"></Icon>
                      </button>
                    </Dropdown.Toggle>
                  </Dropdown>
                </div>
              </SimpleBar>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatMessageScreen;
